import React from "react";

import './BannerBox.scss';

import imgBannerHeader from '../../../assets/banner-header-big.jpg';

import imgBannerHeaderItem1 from '../../../assets/banner-header-1.jpg';
import imgBannerHeaderItem2 from '../../../assets/banner-header-2.jpeg';
import imgBannerHeaderItem3 from '../../../assets/banner-header-3.jpeg';

const BannerBox = () => {
    return(
        <div className="compUIBannerBox">
            <div className="sideBig">
                <ul>
                    <li>
                        <img loading="lazy" src={imgBannerHeader} alt="banner" />
                        <div className="text"> 
                            <div className="box">
                                <p>Quality, and reliability solutions for every customer!</p>
                                <span>A wide range of chemical products, wholesale and retail — for your industry, business, and home.</span>
                            </div> 
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sideSmall">
                <ul>
                    <li>
                        <img loading="lazy" src={imgBannerHeaderItem1} alt="banner" />
                    </li>
                    <li>
                        <img loading="lazy" src={imgBannerHeaderItem2} alt="banner" />
                    </li>
                    <li>
                        <img loading="lazy" src={imgBannerHeaderItem3} alt="banner" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BannerBox;