import React from "react";
import './Category.scss';

const CategoryPage = () => {
    return(
        <div className="categoryPage">
            <ul>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.chemical.jpg" />
                    </div>
                    <div className="text">Chemical products</div>
                    <p>Abrasives</p>
                    <p>Acids</p>
                    <p>Inorganics</p>
                    <p>Organics</p>
                    <p>Other Chemicals</p>
                    <p>Rare Metal Compounds</p>
                    <p>Epoxy resins</p>

                    <button>See more ...</button>
                </li>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.pms.jpg" />
                    </div>
                    <div className="text">Silicone fluids. PMS Oils</div>
                    <p>Low viscosity PMS 5 - PMS 20</p>
                    <p>Medium viscous PMS 50 - PMS 1000</p>
                    <p>High viscosity PMS 10000 - PMS 100000</p>
                    <p>Ultra-high viscosity PMS 200000 - PMS 1000000</p>

                    <button>See more ...</button>
                </li>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.metall.jpg" />
                    </div>
                    <div className="text">Metal Powders and Powders</div>
                    <p>Aluminum Powders</p>
                    <p>Bronze Powders</p>
                    <p>Vanadium Powders</p>
                    <p>Tungsten Powders</p>
                    <p>Iron Powders</p>
                    <p>Silicon Powders</p>
                    <p>Brass Powders</p>
                    <p>Copper Powders</p>
                    <p>Molybdenum Powders</p>
                    <p>Neisilber Powder</p>

                    <button>See more ...</button>
                </li>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.food.jpg" />
                    </div>
                    <div className="text">Food additives</div>
                    <p>Food flavorings</p>
                    <p>Gelatin, Agar, Pectin</p>
                    <p>Cocoa Products</p>
                    <p>Food dyes</p>
                    <p>Flours, Starches</p>
                    <p>Food Ingredients</p>
                    <p>Vegetable and Fatty Oils</p>
                    <p>Sugar Substitutes</p>
                    <p>Salts</p>
                    <p>Milk powder, cream</p>

                    <button>See more ...</button>
                </li>

                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.perfume.jpg" />
                    </div>
                    <div className="text">Perfumery and Cosmetic Raw Materials</div>
                    <p>Assets and Vitamins</p>
                    <p>Bases and Foundations</p>
                    <p>Waxes</p>
                    <p>Clays. Scrubs. Loofah</p>
                    <p>Thickeners</p>
                    <p>Preservatives</p>
                    <p>Cosmetic dyes</p>
                    <p>Natural Resins</p>
                    <p>Fragrances</p>
                    <p>Perfumery Silicones</p>

                    <button>See more ...</button>
                </li>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.laba.jpg" />
                    </div>
                    <div className="text">Laboratory Equipment</div>
                    <p>Magnetic Stirrers</p>
                    <p>Pipettors</p>

                    <button>See more ...</button>
                </li>
                <li>
                    <div className="ico">
                        <img alt="" src="../assets/cat.tela.png" />
                    </div>
                    <div className="text">Grinding Matter</div>
                    <p>Ceramic Beads</p>
                    <p>WC Balls Unpolished</p>
                    <p>WC Balls Polished</p>
                    <p>AISI 304 Stainless Steel Balls</p>

                    <button>See more ...</button>
                </li>
            </ul>
        </div>
    );
};

export default CategoryPage;