import React from "react";

import './Footer.scss';

const Footer = () => {
    return(
        <footer>
            <div className="wrap">
                <div className="logo"><img alt="footer logo" src="../assets/logo.header.png" /></div>
                <div className="list">
                    <ul>
                        <li>Category</li>
                        <li>Chemical products</li>
                        <li>Silicone fluids. PMS Oils</li>
                        <li>Metal Powders and Powders</li>
                        <li>Food additives</li>
                        <li>Perfumery and Cosmetic Raw Materials</li>
                        <li>Laboratory Equipment</li>
                        <li>Grinding Matter</li>
                    </ul>
                </div>

                <div className="list">
                    <ul>
                        <li>About Mendeleev</li>
                        <li>Company info</li>
                        <li>Advertise with us</li>
                        <li>Policies</li>
                        <li>Seller Information Center</li>
                        <li>Contact us</li>
                    </ul>
                </div>

                <div className="list">
                    <ul>
                        <li>Call center working hours</li>
                        <li>Mon-Fri 8:00 to 20:00</li>
                        <li>Sat-Fri 10.00 to 19.00</li>
                    </ul>
                </div>

                <div className="list">
                    <ul>
                        <li>Stay connected</li>
                        <li>email: hello@mendeleev.store</li>
                        <li>facebook: @mendeleev.store</li>
                    </ul>
                </div>
            </div>
            <div className="copy">
                © 2024 Mendeleev.store. All Rights Reserved.
            </div>
        </footer>
    );
};

export default Footer;