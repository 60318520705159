import React from "react";

import './BannerBox.scss';

const BannerPage = () => {
    return(
        <div className="compUIBannerPage">
            <ul>
                <li>
                    <div className="ico"><img alt="" src="../assets/s-l2400.png" /></div>
                </li>
                <li>
                    <div className="ico"><img alt="" src="../assets/s-l2400-2.png" /></div>
                </li>
            </ul>
        </div>
    );
};

export default BannerPage;