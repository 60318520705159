import React from "react";

import icoSVG from "../../../assets/ico/ico";

import './Wall.scss';
import { Link } from "react-router-dom";

const Wall = ({titleH2, grid}) => {

    const arrWall = [
        { name: 'Xanthan Gum E415', ico: '../assets/1v7oh24.jpeg', href: '/goods/asd-1' },
        { name: 'XYLITOL XYLITOL E967 Crystalline Xylitol', ico: '../assets/2v6hrlz.jpeg', href: '/goods/asd-2' },
        { name: 'Lecithin Soy Liquid', ico: '../assets/3xqnr6u.jpg', href: '/goods/asd-3' },
        { name: 'Citric Acid', ico: '../assets/914pnzr.png', href: '/goods/asd-4' },
        { name: 'Magnesium Carbonate', ico: '../assets/ac2bf91.png', href: '/goods/asd-5' },
        { name: 'Lactic Acid 60%. Powder', ico: '../assets/anpr56t.jpg', href: '/goods/asd-6' },
        { name: 'Lactic Acid 80%. Solution', ico: '../assets/i8atbdn.png', href: '/goods/asd-7' },
        { name: 'Sodium Citrate', ico: '../assets/jbcsfr2.jpeg', href: '/goods/asd-8' },
        { name: 'Palm Oil Butter. Refined', ico: '../assets/rgjxv2z.png', href: '/goods/asd-9' },
        { name: 'Salicylic Acid. Food grade. Powder', ico: '../assets/wpk3fjh.jpeg', href: '/goods/asd-10' },
        //{ name: 'Caliper Digital Caliper Steel Case', ico: '../assets/yli2htp.jpg' },
    ];

    return(
        <div className="compUIWall">
            <h2>{titleH2}</h2>
            <ul className={grid}>

                {arrWall.map((item, index) => (
                    <React.Fragment key={'wall-goods-' + index}>
                    <li key={index}>
                        <div className="ico">
                            <Link to={item.href}><img src={item.ico} alt="" /></Link>
                            <div className="boxOffer">
                                <span className="sale">Sale</span>
                                <span className="best">Best Seller</span>
                            </div>

                            <div className="ctrl">
                                <div className="circleCtrl"><span dangerouslySetInnerHTML={{ __html: icoSVG.find(icon => icon.name === 'heart').svg }}></span></div>
                                <div className="circleCtrl"><span dangerouslySetInnerHTML={{ __html: icoSVG.find(icon => icon.name === 'scales').svg }}></span></div>
                            </div>
                        </div>
                        <div className="info">
                            <div className="price">
                                <div className="amount"><sup>$</sup><span>445</span></div>
                                <div className="sale"><del>$800</del> <span>Save: $355</span></div>
                            </div>
                            <div className="title">{item.name}</div>
                            <div className="description textClip clip-2">Titanium oxide (IV), titanium dioxide, titanium whites, titanium anhydride, CI 77891, Pigment</div>
                            <button><span dangerouslySetInnerHTML={{ __html: icoSVG.find(icon => icon.name === 'shop').svg }}></span> Add to cart</button>
                        </div>
                    </li>
                    </React.Fragment>
                ))}

            </ul>
        </div>
    );
};

export default Wall;