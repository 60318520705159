import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import Header from './components/ui/Header/Header';
import Footer from './components/ui/Footer/Footer';

import PageHome from './components/containers/PageHome/PageHome';
import PageCatalog from './components/containers/PageCatalog/PageCatalog';
import PageGoods from './components/containers/PageGoods/PageGoods';


function App(){
    return(
        <div className='App'>
            <Router>
                <Header />

                <main>
                    <Routes>
                        <Route path='/' element={<PageHome />} />
                        <Route path='/catalog/:cat' element={<PageCatalog />} />

                        <Route path='/goods/:goods' element={<PageGoods />} />
                    </Routes>
                </main>

                <Footer />
            </Router>
        </div>
    );
}

export default App;