const icoSVG = [
    { name: 'arrow-left', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="216" x2="40" y1="128" y2="128"/><polyline fill="none" points="112 56 40 128 112 200" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>'},
    { name: 'arrow-n-line', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><polyline fill="none" points="96 48 176 128 96 208" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>' },
    { name: 'menu-burger', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><rect class="rect" fill="none" height="56.57" rx="8" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" transform="translate(-7.8 109.3) rotate(-45)" width="56.6" x="99.7" y="35.7"/><rect class="rect" fill="none" height="56.57" rx="8" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" transform="translate(-34.3 173.3) rotate(-45)" width="56.6" x="163.7" y="99.7"/><rect class="rect" fill="none" height="56.57" rx="8" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" transform="translate(-71.8 82.7) rotate(-45)" width="56.6" x="35.7" y="99.7"/><rect class="rect" fill="none" height="56.57" rx="8" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" transform="translate(-98.3 146.7) rotate(-45)" width="56.6" x="99.7" y="163.7"/></svg>' },
    { name: 'search', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><circle cx="116" cy="116" fill="none" r="84" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="175.4" x2="224" y1="175.4" y2="224"/></svg>'},
    { name: 'heart', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><path d="M128,216S28,160,28,92A52,52,0,0,1,128,72h0A52,52,0,0,1,228,92C228,160,128,216,128,216Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>' },
    { name: 'scales', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="128" x2="128" y1="40" y2="216"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="104" x2="152" y1="216" y2="216"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="56" x2="200" y1="88" y2="56"/><path d="M24,168c0,17.7,20,24,32,24s32-6.3,32-24L56,88Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M168,136c0,17.7,20,24,32,24s32-6.3,32-24L200,56Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>' },
    { name: 'shop', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><path d="M184,184H69.8L41.9,30.6A8,8,0,0,0,34.1,24H16" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><circle cx="80" cy="204" fill="none" r="20" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><circle cx="184" cy="204" fill="none" r="20" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M62.5,144H188.1a15.9,15.9,0,0,0,15.7-13.1L216,64H48" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>' },
    { name: 'plus', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><circle cx="128" cy="128" fill="none" r="96" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="88" x2="168" y1="128" y2="128"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="128" x2="128" y1="88" y2="168"/></svg>' },
    { name: 'delivery', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><path d="M176,80h42.6a7.9,7.9,0,0,1,7.4,5l14,35" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="16" x2="176" y1="144" y2="144"/><circle cx="188" cy="192" fill="none" r="24" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><circle cx="68" cy="192" fill="none" r="24" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="164" x2="92" y1="192" y2="192"/><path d="M44,192H24a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8H176V171.2" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M176,120h64v64a8,8,0,0,1-8,8H212" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>' },
    { name: 'diff', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><circle cx="196" cy="188" fill="none" r="28" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M196,160V119.9a48.2,48.2,0,0,0-14.1-34L144,48" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><polyline fill="none" points="144 88 144 48 184 48" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><circle cx="60" cy="68" fill="none" r="28" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M60,96v40.1a48.2,48.2,0,0,0,14.1,34L112,208" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><polyline fill="none" points="112 168 112 208 72 208" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/></svg>'},
    { name: 'card', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><rect class="rect" fill="none" height="144" rx="8" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" width="208" x="24" y="56"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="168" x2="200" y1="168" y2="168"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="120" x2="136" y1="168" y2="168"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="24" x2="232" y1="96.9" y2="96.9"/></svg>' },
    { name: 'coin', svg: '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><ellipse cx="104" cy="128" fill="none" rx="56" ry="96" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><path d="M104,32h48c30.9,0,56,43,56,96s-25.1,96-56,96H104" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="145.7" x2="193.7" y1="64" y2="64"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="160" x2="208" y1="128" y2="128"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" x1="145.7" x2="193.7" y1="192" y2="192"/></svg>'},
];

export default icoSVG;