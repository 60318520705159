import React from "react";
import { Link } from "react-router-dom";

import './Category.scss';

import icosvg from '../../../assets/ico/ico';

const Category = () => {

    let arrMainCategory = [
        { name: 'Chemical products', href: '/catalog/chemical' },
        { name: 'Food Additives', href: '/catalog/food' },
        { name: 'Polymers and Plastics. Fabrics', href: '/catalog/polymers' },
        { name: 'Silicone Liquids. PMS Oils', href: '/catalog/silicone' },
        { name: 'Milling Media', href: '/catalog/milling' },
        { name: 'Metal Powders and Powders', href: '/catalog/metal-powders' },
        { name: 'Pearl Pigments', href: '/catalog/pearl' },
        { name: 'Oils. Lubricants. Pastes', href: '/catalog/oils' },
        { name: 'Varnishes. Paints. Solvents', href: '/catalog/varnishes' },
        { name: 'Perfume and Cosmetic Raw Materials', href: '/catalog/perfume' },
        { name: 'Tools', href: '/catalog/tools' },
        { name: 'Gifts of Nature', href: '/catalog/gifts' },
        { name: 'Hobbies', href: '/catalog/hobbies' },
        { name: 'Tubes, Hoses', href: '/catalog/tubes' },
        { name: 'Neodymium Magnet', href: '/catalog/neodymium' },
        { name: 'Tungsten Electrodes', href: '/catalog/tungsten' },
        { name: 'Containers', href: '/catalog/containers' },
        { name: 'Laboratory Equipment', href: '/catalog/laboratory' },
        { name: 'Titanium', href: '/catalog/titanium' },
        { name: 'Metal', href: '/catalog/metal' },
    ];

    return(
        <div className="defCategoryLeft">
            <ul>
                {arrMainCategory.map((item, index) => (
                    <React.Fragment key={'fragmentCategory-' + index}>
                        <li key={'category-' + index}><Link to={item.href}><span>{item.name}</span> <span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'arrow-n-line').svg }}></span></Link></li>
                    </React.Fragment>
                ))}
                
            </ul>
        </div>
    );
};

export default Category;