import React from "react";
import { Link } from "react-router-dom";

import './PageCaption.scss';

const PageCaption = ({titleH1, arrHref}) => {
    return(
        <div className="compUIpageCaption">
            <div className="wrap">
                <div className="breadCrumbs">
                    <ul>
                        <li key="bread-main"><Link to={"/"}>Home</Link></li>
                        <li key="bread-main-arrow">&#8250;</li>
                        {arrHref.map((item, index) => (
                            <React.Fragment key={'caption' + index}>
                                { index < arrHref.length - 1 ? (<li id={'bread-' + index}><Link to={item.href}>{item.name}</Link></li>) : ( <li id={index}>{item.name}</li>) }
                                { index < arrHref.length - 1 && <li id={'bread-' + index + 125}>&#8250;</li>}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
               <h1>{titleH1}</h1> 
            </div>
        </div>
    );
};

export default PageCaption;