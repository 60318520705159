import React from "react";
import { useParams } from 'react-router-dom';

import './PageGoods.scss';

import icosvg from '../../../assets/ico/ico';

import ScrollToTop from "../../utils/ScrollTop";

import PageCaption from "../../ui/PageCaption/PageCaption";
import Wall from "../../ui/Wall/Wall";

const PageGoods = () => {

    const { goods } = useParams();

    const arrBreadcrumbs = [
        {name: 'All catalog', href: '/catalog/all'},
        {name: goods, href: '/'},
    ];

    return (
        <div className="pageCatalog">
            <ScrollToTop />
            <PageCaption arrHref={arrBreadcrumbs} titleH1={'Salicylic Acid. Food grade. Powder'} />
            <div className="wrap side">
                <div className="sideGallery">
                    <ul>
                        <li><img alt="" src="../assets/1v7oh24.jpeg" /></li>
                        <li><img alt="" src="../assets/2v6hrlz.jpeg" /></li>
                        <li><img alt="" src="../assets/3xqnr6u.jpg" /></li>
                        <li><img alt="" src="../assets/914pnzr.png" /></li>
                        <li><img alt="" src="../assets/ac2bf91.png" /></li>
                    </ul>
                    <div className="icoBig"> <img alt="" src="../assets/2v6hrlz.jpeg" /> </div>
                </div>
                <div className="sideInfo">
                    <div className="sku">
                        <ul>
                            <li className="active">
                                <div className="title"><strong>1</strong> <small>kg</small></div>
                                <div className="price"><sup>$</sup>445</div>
                            </li>
                            <li>
                                <div className="title"><strong>5</strong> <small>kg</small></div>
                                <div className="price"><sup>$</sup>2 225</div>
                            </li>
                            <li>
                                <div className="title"><strong>10</strong> <small>kg</small></div>
                                <div className="price"><sup>$</sup>4 450</div>
                            </li>
                            <li>
                                <div className="title"><strong>25</strong> <small>kg</small></div>
                                <div className="price"><sup>$</sup>11 125</div>
                            </li>
                        </ul>
                    </div>
                    <div className="about">
                        <h3>About the product</h3>
                        <ul>
                            <li>
                                <span>Country of manufacture</span>
                                <span>China</span>
                            </li>
                            <li>
                                <span>Form of product release</span>
                                <span>Capsules</span>
                            </li>
                            <li>
                                <span>Product volume</span>
                                <span>4000 ml</span>
                            </li>
                            <li>
                                <span>Minimum humidity, %</span>
                                <span>25</span>
                            </li>
                            <li>
                                <span>Maximum humidity, %</span>
                                <span>75</span>
                            </li>
                        </ul>
                        <div className="text">
                        The hardest and most durable beads with maximum durability. It is made of zirconium oxide partially stabilized in cubic modification with yttrium oxide. This type of bead is characterized by a very high density (6.01 kg/l) and, consequently, a high impact energy of the grinding bodies during the milling process. It has the highest wear resistance compared to other types of grinding media (mass loss of beads is ~11 times less than that of zirconium silicate and ~80 times less than that of ordinary glass beads).
                        </div>
                    </div>
                </div>
                <div className="sideBuy">
                    <div className="boxBuy">
                        <div className="price">
                            <div className="amount"><sup>$</sup>445</div>
                            <div className="sale">
                                <del>$800</del> <span>Save: $355</span>
                            </div>
                        </div>

                        <div className="char">
                            <ul>
                                <li><span>Shipping</span> <span>Read item description or contact seller for shipping options. See detailsfor shipping</span></li>
                                <li><span>Delivery</span> <span>Varies</span></li>
                                <li><span>Returns</span> <span>30 days returns. Buyer pays for return shipping.</span></li>
                            </ul>
                        </div>

                        <button><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }} /> Add to cart</button>
                    </div>

                    <div className="text">
                        <ul>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'delivery').svg }} /> <p>Delivery terms</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'diff').svg }} /> <p>Return of goods</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'card').svg }} /> <p>Payment methods</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'coin').svg }} /> <p>Refunds</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className="wrap">
                <Wall titleH2={'You May Also Like'} grid={'el5'} />
            </div>
            
        </div>
    );
};

export default PageGoods;