import React from "react";
import './Header.scss';

import icosvg from '../../../assets/ico/ico';
import { Link } from "react-router-dom";



const Header = () => {
    return(
        <header className="header">
            <div className="headerWhite">
                <div className="wrap">

                    <div className="logo">
                        <Link to={"/"}><img src="../assets/logo.header.png" alt="logo" /></Link>
                    </div>

                    <div className="language">
                        <div className="select">
                            <div className="value"><span>English</span> <span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'arrow-n-line').svg }} />  </div>
                            <div className="set">
                                <div className="it"><span>English</span></div>
                                <div className="it"><span>Croatian</span></div>
                                <div className="it"><span>Russian</span></div>
                            </div>
                        </div>
                    </div>

                    <div className="link">
                        <ul>
                            <li>Sale</li>
                            <li>Gift cart</li>
                            <li>About the company</li>
                            <li>Our warehouses</li>
                            <li>Contacts</li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="headerBack">
                <div className="wrap">
                    <Link to={"/catalog/all"} className="catalogBtn"><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'menu-burger').svg }} />  All catalog <span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'arrow-left').svg }} /></Link>
                    <div className="search">
                        <form>
                            <fieldset>
                                <div className="input">
                                    <input type="text" name="search" defaultValue="" />
                                    <button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'search').svg }}></button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="controll">
                        <button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'scales').svg }}></button>
                        <button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'heart').svg }}></button>
                    </div>
                    <div className="cart"><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }}></span> Shop cart</div>
                </div>
            </div>

        </header>
    );
};

export default Header;