import React from "react";
import { useParams } from 'react-router-dom';

import './PageCatalog.scss';

import Category from "../../ui/Category/Category";
import Wall from "../../ui/Wall/Wall";
import PageCaption from "../../ui/PageCaption/PageCaption";

import ScrollToTop from "../../utils/ScrollTop";

const PageCatalog = () => {

    const { cat } = useParams();

    const arrBreadcrumbs = [
        {name: 'All catalog', href: '/catalog/all'},
        {name: cat, href: '/'},
    ];

    return (
        <div className="pageCatalog">
            <ScrollToTop />
            <PageCaption arrHref={arrBreadcrumbs} titleH1={cat} />

            <div className="wrap wrapGrid">
                <div className="sideCategory">
                    <Category />
                </div>

                <div className="sideContent">
                    <Wall titleH2={'All catalog'} grid={'el4'} />
                </div>
            </div>
        </div>
    );
};

export default PageCatalog;